<template>
  <div class="v-stack gap-3 h-stretch">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Set Gas Price</div>
            <input type="number" v-model="gasPrice" />
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="setGasPrice()" class="edit">Submit</button>
        </div>
      </div>
    </div>

    <div class="h-stack h-space-between">
      <div class="h-stack h-start gap-3 v-stretch">
        <a class="heading-title-2" style="align-self: center">Cars</a>
        <label style="align-self: center">Gas Price {{ gasPriceActual }} kc</label>
      </div>
      <div class="h-stack h-end gap-3" v-if="$store.state.accessLevel == 10">
        <button class="add" @click="showGasDialog">Set Gas Price</button>
        <button class="add" @click="$router.push('/lifecars/car/add')">
          New Car
        </button>
      </div>
    </div>
    <Table :items="cars" :defaultColumn="0" v-on:rowSelected="$router.push('/lifecars/car/detail/' + $event._id)"
      :css="'1fr 80px 100px 100px 120px'" :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Consumption (l/100km)',
          sort: 'numeric',
          path: ['consumption'],
          format: 'l',
          alignTitle: 'right',
          styleFunction: () => 'text-align: right',
        },
        {
          name: 'Depreciation (kc/1km)',
          sort: 'numeric',
          path: ['useRatePerKm'],
          formatType: 'currency',
          format: 'czk',
          formatMap: (item) => item.toFixed(2),
        },
        {
          name: 'Total Cost (kc/1km)',
          sort: 'numeric',
          formatType: 'currency',
          format: 'czk',
          formatMap: (item) =>
            (
              Math.round(
                ((item.consumption / 100) * gasPriceActual +
                  item.useRatePerKm) *
                100
              ) / 100
            ).toFixed(2),
        },
        {
          name: 'Borrow',
        },
      ]"><template v-slot:Borrow="{ item }">
        <div class="h-stack">
          <button class="add" @click="$router.push('/lifecars/car/trip/add/' + item._id)">
            Borrow
          </button>
        </div>
      </template></Table>
    <a class="heading-title-2" style="align-self: center">Reserved</a>
    <Table :items="reservedRentals" :css="'120px 80px 80px 80px 80px 1fr 80px'"
      v-on:rowSelected="$router.push('/lifecars/car/trip/edit/' + $event._id)" :columns="[
        {
          name: 'User',
          sort: 'alphabet',
          path: ['user', 'fullName'],
        },
        {
          name: 'Car',
          sort: 'alphabet',
          path: ['car'],
        },
        {
          name: 'From',
          sort: 'date',
          path: ['startDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'To',
          sort: 'date',
          path: ['endDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Km',
          sort: 'numeric',
          path: ['km'],
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
        {
          name: 'Price',
          sort: 'numeric',
          path: ['price'],
          formatType: 'currency',
          format: 'czk',
          formatMap: (item) => item.toFixed(2),
        },
      ]"></Table>
    <a class="heading-title-2" style="align-self: center">Submitted</a>
    <Table :items="submittedRentals" :css="'120px 80px 80px 80px 80px 1fr 80px'" :columns="[
      {
        name: 'User',
        sort: 'alphabet',
        path: ['user', 'fullName'],
      },
      {
        name: 'Car',
        sort: 'alphabet',
        path: ['car'],
      },
      {
        name: 'From',
        sort: 'date',
        path: ['startDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'To',
        sort: 'date',
        path: ['endDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'Km',
        sort: 'numeric',
        path: ['km'],
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      },
      {
        name: 'Price',
        sort: 'numeric',
        path: ['price'],
        formatType: 'currency',
        format: 'czk',
        formatMap: (item) => item.toFixed(2),
      },
    ]"></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import utils from "@/utils";

export default {
  data() {
    return {
      cars: [],
      gasPrice: 0,
      gasPriceActual: 0,
    };
  },
  components: {
    Table,
  },
  computed: {
    reservedRentals() {
      const days = [];
      for (const car of this.cars) {
        for (const day of car.rentalDays) {
          if (!day.submitted) {
            if (
              this.$store.state.accessLevel < 10 &&
              day.user._id != this.$store.state.id
            ) {
              continue;
            }

            day.car = car.name;

            day.price = utils.carRentalObject.price(
              car,
              day,
              this.gasPriceActual
            );

            days.push(day);
          }
        }
      }
      return days;
    },
    submittedRentals() {
      const days = [];
      for (const car of this.cars) {
        for (const day of car.rentalDays) {
          if (day.submitted) {
            if (
              this.$store.state.accessLevel < 10 &&
              day.user._id != this.$store.state.id
            ) {
              continue;
            }

            day.car = car.name;
            days.push(day);
          }
        }
      }
      return days;
    },
  },
  methods: {
    ...mapActions(["getCars", "getGasPrice", "addGasPrice"]),
    showGasDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    setGasPrice() {
      this.dismiss();
      this.addGasPrice({
        price: this.gasPrice,
      })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh() {
      this.getGasPrice()
        .then((gasPrice) => {
          if (gasPrice) {
            this.gasPriceActual = gasPrice.price;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCars()
      .then((cars) => {
        this.cars = cars;
      })
      .catch((error) => {
        console.log(error);
      });
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.heading-title-2 {
  padding: 0px !important;
}
</style>
