var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack gap-3 h-stretch"},[_c('div',{ref:"overlay",staticClass:"overlay"},[_c('div',{staticClass:"card dynamic"},[_c('div',{staticClass:"content mt-3"},[_c('div',{staticClass:"v-stack h-stretch gap-5"},[_c('div',[_vm._v("Set Gas Price")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gasPrice),expression:"gasPrice"}],attrs:{"type":"number"},domProps:{"value":(_vm.gasPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.gasPrice=$event.target.value}}})])]),_c('div',{staticClass:"footer gap-3 h-space-between mt-3"},[_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.setGasPrice()}}},[_vm._v("Submit")])])])]),_c('div',{staticClass:"h-stack h-space-between"},[_c('div',{staticClass:"h-stack h-start gap-3 v-stretch"},[_c('a',{staticClass:"heading-title-2",staticStyle:{"align-self":"center"}},[_vm._v("Cars")]),_c('label',{staticStyle:{"align-self":"center"}},[_vm._v("Gas Price "+_vm._s(_vm.gasPriceActual)+" kc")])]),(_vm.$store.state.accessLevel == 10)?_c('div',{staticClass:"h-stack h-end gap-3"},[_c('button',{staticClass:"add",on:{"click":_vm.showGasDialog}},[_vm._v("Set Gas Price")]),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/lifecars/car/add')}}},[_vm._v(" New Car ")])]):_vm._e()]),_c('Table',{attrs:{"items":_vm.cars,"defaultColumn":0,"css":'1fr 80px 100px 100px 120px',"columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Consumption (l/100km)',
        sort: 'numeric',
        path: ['consumption'],
        format: 'l',
        alignTitle: 'right',
        styleFunction: function () { return 'text-align: right'; },
      },
      {
        name: 'Depreciation (kc/1km)',
        sort: 'numeric',
        path: ['useRatePerKm'],
        formatType: 'currency',
        format: 'czk',
        formatMap: function (item) { return item.toFixed(2); },
      },
      {
        name: 'Total Cost (kc/1km)',
        sort: 'numeric',
        formatType: 'currency',
        format: 'czk',
        formatMap: function (item) { return (
            Math.round(
              ((item.consumption / 100) * _vm.gasPriceActual +
                item.useRatePerKm) *
              100
            ) / 100
          ).toFixed(2); },
      },
      {
        name: 'Borrow',
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/lifecars/car/detail/' + $event._id)}},scopedSlots:_vm._u([{key:"Borrow",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"h-stack"},[_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/lifecars/car/trip/add/' + item._id)}}},[_vm._v(" Borrow ")])])]}}])}),_c('a',{staticClass:"heading-title-2",staticStyle:{"align-self":"center"}},[_vm._v("Reserved")]),_c('Table',{attrs:{"items":_vm.reservedRentals,"css":'120px 80px 80px 80px 80px 1fr 80px',"columns":[
      {
        name: 'User',
        sort: 'alphabet',
        path: ['user', 'fullName'],
      },
      {
        name: 'Car',
        sort: 'alphabet',
        path: ['car'],
      },
      {
        name: 'From',
        sort: 'date',
        path: ['startDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'To',
        sort: 'date',
        path: ['endDate'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'Km',
        sort: 'numeric',
        path: ['km'],
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      },
      {
        name: 'Price',
        sort: 'numeric',
        path: ['price'],
        formatType: 'currency',
        format: 'czk',
        formatMap: function (item) { return item.toFixed(2); },
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/lifecars/car/trip/edit/' + $event._id)}}}),_c('a',{staticClass:"heading-title-2",staticStyle:{"align-self":"center"}},[_vm._v("Submitted")]),_c('Table',{attrs:{"items":_vm.submittedRentals,"css":'120px 80px 80px 80px 80px 1fr 80px',"columns":[
    {
      name: 'User',
      sort: 'alphabet',
      path: ['user', 'fullName'],
    },
    {
      name: 'Car',
      sort: 'alphabet',
      path: ['car'],
    },
    {
      name: 'From',
      sort: 'date',
      path: ['startDate'],
      formatType: 'date',
      format: 'DD.MM.YYYY',
    },
    {
      name: 'To',
      sort: 'date',
      path: ['endDate'],
      formatType: 'date',
      format: 'DD.MM.YYYY',
    },
    {
      name: 'Km',
      sort: 'numeric',
      path: ['km'],
    },
    {
      name: 'Description',
      sort: 'alphabet',
      path: ['description'],
    },
    {
      name: 'Price',
      sort: 'numeric',
      path: ['price'],
      formatType: 'currency',
      format: 'czk',
      formatMap: function (item) { return item.toFixed(2); },
    } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }